import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import email from './email';
const $toast = useToast();

export const state = {
  timelines: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  isLoading: true,
  timelineCounts: {
    totalCount: 0,
    unSeenCount: 0,
    waitingOnCount: 0,
    importantCount: 0,
    overdueCount: 0,
    binCount: 0,
    archiveCount: 0,
    draftCount: 0,
    sentCount: 0,
  },

}
export const getters = {
  timelines: s => s.timelines,
  isLoading: s => s.isLoading,
  getTimelineById: (s) => (id) => {
    return s.timelines.items.find(item => item.id === id);
  },
  timelineCounts: s => s.timelineCounts,

};
export const actions = {
  addTaskIntoTimeline({ commit }, task) {
    commit("ADD_TASK", task);
  },
  addNoteIntoTimeline({ commit }, note) {
    commit("ADD_NOTE", note);
  },
  addEventIntoTimeline(s, event) {
    console.log(event);
  },
  async fetchTimelines({ commit }, requestData) {
    return await api.get(`timeline`, requestData).then(response => {
      if (response) {
        commit("SET_TIMELINES", response.data);
      }
      return true;
    });
  },
  async fetchTimeline({ commit }, timelineId) {
    return await api.getById(`timeline`, timelineId).then(response => {
      if (response) {
        commit("SET_TIMELINE", response.data);
      }
      return true;
    });
  },
  async fetchEmailBody({ commit }, { timelineId, emailId }) {
    return await api.getEmailBody(`email/body`, { emailId: emailId }).then(response => {
      if (response) {
        commit("SET_EMAILDETAIL", { timelineId: timelineId, emailId: emailId, data: response.data });
      }
      return true;
    });


  },
  async fetchTimelineCount({ commit }) {
    try {
      const response = await api.get(`timeline/count`, {});
      if (response.data) {
        commit("SET_COUNT", response.data);
        return true;
      }
      return false; // Handle case where response.data is falsy
    } catch (error) {
      console.error('Error fetching timeline count:', error);
      return false; // Return false or handle error appropriately
    }
  },
  async toggleArchive({ commit, dispatch }, request) {
    if (typeof (request) === 'string') {
      const timelineId = request
      return await api.toggle(`timeline/${timelineId}/toggleArchive`)
        .then((response) => {
          if (response) {
            commit("UPDATE_ARCHIVE", timelineId);
            dispatch('fetchTimelineCount');
            return true;
          }
          return false;
        });
    } else { // request is object
      return api.insert(`timeline/toggleArchive`, request)
        .then((response) => {
          if (response) {
            commit("UPDATE_ARCHIVE", request.ids);
            $toast.open({
              message: 'Emails Moved',
              type: 'info',
              duration: 3000,
              dismissible: true
            })
            dispatch('fetchTimelineCount');
            return true;
          }
          return false;
        });
    }
  },
  async deleteTimeline({ commit, dispatch }, request) {
    return await api.deleteWithRequest(`timeline/moveToBin`, request)
      .then((response) => {
        if (response) {
          commit("REMOVE", request.ids);
          $toast.open({
            message: 'Email Deleted',
            type: 'warning',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async toggleImportant({ commit, dispatch }, timelineId) {
    return api.toggle(`timeline/${timelineId}/toggleImportant`)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", timelineId);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsImportant({ commit, dispatch }, request) {
    return api.insert(`timeline/markAsImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", response.data);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsNotImportant({ commit, dispatch }, request) {
    return api.insert(`timeline/markAsNotImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", response.data);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async toggleRead({ commit, dispatch }, timelineId) {
    return api.toggle(`timeline/${timelineId}/toggleRead`)
      .then((response) => {
        if (response) {
          commit("UPDATE", timelineId);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsRead({ commit, dispatch }, request) {
    return api.insert(`timeline/markAsRead`, request)
      .then((response) => {
        if (response) {
          commit("MARK_AS_READ", request.ids);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsUnRead({ commit, dispatch }, request) {
    return api.insert(`timeline/markAsUnRead`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_READ", request.ids);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async toggleWaitingOn({ commit, dispatch }, request) {
    return api.insert(`timeline/toggleWaitingOn`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_WAITING", request.timelineId);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
}
export const mutations = {
  SET_TIMELINES(s, timelines) {
    s.timelines = timelines;
    s.isLoading = false;
  },
  ADD_TASK(s, task) {
    const timeline = s.timelines.items.find(t => t.id === task.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: task.id,
        assignDate: task.createDate,
        isDeleted: task.isDeleted,
        isImportant: task.isImportant,
        itemType: 2,
        timelineId: task.timelineId,
        title: task.title
      })
      timeline.tasks.push(task);
      timeline.taskCount++;
    }
  },
  ADD_NOTE(s, note) {
    const timeline = s.timelines.items.find(t => t.id === note.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: note.id,
        assignDate: note.createDate,
        isDeleted: note.isDeleted,
        isImportant: note.isImportant,
        itemType: 4,
        timelineId: note.timelineId,
        title: note.title
      })
      timeline.notes.push(note);
      timeline.noteCount++;
    }
  },
  SET_TIMELINE(s, timeline) {
    let seenTimeline = timeline;
    seenTimeline.seen = true;
    const index = s.timelines.items.findIndex(item => item.id === timeline.id);
    if (index === -1) {
      s.timelines.items.push(seenTimeline);
    } else {
      s.timelines.items[index] = seenTimeline;
    }
    s.isLoading = false;
  },
  SET_COUNT(s, response) {
    s.timelineCounts = response
  },
  REMOVE(s, ids) {
    s.timelines.items = s.timelines.items.filter(item => !ids.includes(item.id));
  },
  SET_EMAILDETAIL(s, { timelineId, emailId, data }) {
    const timeline = s.timelines.items.find(t => t.id === timelineId);
    const emailMessage = timeline.emailMessages.find(t => t.id === emailId);
    if (emailMessage) {
      emailMessage.contentHtml = data.contentHtml;
      
    }
  },
  UPDATE_IMPORTANT(s, response) {
    if (typeof (response) === 'string') {
      const timelineId = response
      const timeline = s.timelines.items.find(t => t.id === timelineId);
      if (timeline) {
        timeline.isImportant = !timeline.isImportant;
      }      
    } else {
      response.forEach(response => {
        const timeline = s.timelines.items.find(t => t.id === response.id);
        if (timeline) {
          timeline.isImportant = response.isImportant;
        }
      })
    }
  },
  MARK_AS_READ(s, response) {
    response.forEach(response => {
      let timeline = s.timelines.items.find(t => t.id === response.id);
      if (timeline) {
        timeline.seen = true;
      }
    })
  },
  MARK_AS_UNREAD(s, response) {
    response.forEach(response => {
      let timeline = s.timelines.items.find(t => t.id === response.id);
      if (timeline) {
        timeline.seen = false;
      }
    })
  },

  UPDATE_WAITING(s, timelineId) {
    const timeline = s.timelines.items.find(t => t.id === timelineId);
    if (timeline) {
      if (timeline.waitingOn === true) {
        s.timelineCounts.waitingOnCount--
      }
      else {
        s.timelineCounts.waitingOnCount++
      }
      timeline.waitingOn = !timeline.waitingOn;
    }
  },
  UPDATE_ARCHIVE(s, request) {
    if (typeof (request) === 'string') {
      const timelineId = request
      const timeline = s.timelines.items.find(t => t.id === timelineId)
      if (timeline) {
        timeline.isArchive = !timeline.isArchive
      }
      if (timeline.isArchive) {
        $toast.open({
          message: 'Email Moved To Archive',
          type: 'info',
          duration: 3000,
          dismissible: true
        })
      }
      else {
        $toast.open({
          message: 'Email Moved To Inbox ',
          type: 'info',
          duration: 3000,
          dismissible: true
        })
      }
      return (timeline.isArchive)
    } else {
      request.forEach(r => {
        let timeline = s.timelines.items.find(t => t.id === r.id)
        if (timeline) {
          timeline.isArchive = !timeline.isArchive
        }
      })
    }
  },
}
export default {
  state,
  getters,
  actions,
  mutations
};