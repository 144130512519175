import { UserManager, WebStorageStateStore, Log } from 'oidc-client-ts'
import store from '@/lib/store'

const mgr = new UserManager({
  authority: process.env.VUE_APP_AUTHORITY,
  client_id: `${process.env.VUE_APP_CLIENT_ID}`,
  // redirect_uri: `${process.env.VUE_APP_ADMIN_ADDRESS}/callback.html`,
  redirect_uri: `${window.location.origin}/callback.html`,
  response_type: 'code',
  // response_type: 'id_token token',
  scope: 'openid roles profile email MainBff_api Document_api',
  post_logout_redirect_uri: `${window.location.origin}`,
  userStore: new WebStorageStateStore({ store: window.localStorage }),

  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
  // accessTokenExpiringNotificationTime: 3580, // default token time 3600
  // checkSessionInterval:10, // check the session status, fail when check session because of the x-frame-options not sameorigin
  filterProtocolClaims: true,
  loadUserInfo: true
})

export const auth = {
  async authenticate(returnPath) {
    const user = await auth.getUser() //see if the user details are in local storage
    if (user) {
      store.dispatch('user/fetchUser', user)
    } else {
      store.dispatch('user/fetchUser', null)
      auth.signIn(returnPath)
    }
  },
  async loadUserToStore() {
    try {
      let user = await mgr.getUser()
      if (user) {
        store.dispatch('user/fetchUser', user)
        store.dispatch('note/fetchNotesAndCounts', null, { root: true });
      }
    } catch (err) {
      console.error(err)
    }
  },
  async getUser() {
    try {
      let user = await mgr.getUser()
      return user
    } catch (err) {
      console.error(err)
    }
  },
  signIn(returnPath) {
    returnPath ? mgr.signinRedirect({ state: returnPath }) : mgr.signinRedirect()
  },
  signInSilent() {
    sessionStorage.setItem('redirectedUri', window.location.href)
    mgr.signinSilent()
  },
  signinSilentCallback() {
    mgr.signinSilentCallback()
  },
  signOut() {
    const oidcKeys = []
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 5) == 'oidc.') {
        oidcKeys.push(localStorage.key(i))
      }
    }

    for (let i = 0; i < oidcKeys.length; i++) {
      localStorage.removeItem(oidcKeys[i])
    }
    
    mgr
      .signoutRedirect()
      .then(() => {})
      .catch((err) => {
        console.log(err)
      })
  },
  async getRole() {
    return mgr
      .getUser()
      .then((user) => {
        if (user == null) {
          return self.signIn()
        } else {
          return user.profile.role
        }
      })
      .catch((err) => {
        return err
      })
  }
}

Log.logger = console
Log.level = Log.INFO

mgr.events.addUserLoaded((user) => {
  store.dispatch('user/fetchUser', user)
})

mgr.events.addAccessTokenExpiring(() => {
  mgr.startSilentRenew()
})

mgr.events.addAccessTokenExpired(() => {
  mgr.signinRedirect().catch((err) => {
    console.log(err)
  })
})

mgr.events.addSilentRenewError(() => {
  console.error('Silent Renew Error：')
})

mgr.events.addUserSignedOut(() => {
  mgr.signinRedirect().catch((err) => {
    console.error(err)
  })
})
